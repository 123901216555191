import generateUuid from './uuid'
import getUrlParams from './getUrlParams'

const windowWidth =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

const sidebarOpen = windowWidth > 500

export default () => ({
  uuid: generateUuid(),
  loadingData: false,
  autosave: false,
  readOnly: false,
  schema: null,
  data: {
    _lists: {},
    _relations: {}
  },
  currentPage: null,
  sidebarOpen,
  showImprint: false,
  showVariableNames: false,
  acceptTerms: true,
  isLoggedIn: false,
  userName: null,
  token: null,

  // derived
  activePages: [],
  showValidation: {},
  nextPage: null,
  prevPage: null,

  workflowStatus: 'eingabeEinrichtung',
  modalForms: {},
  location: getUrlParams()
})
