import generateUuid from './uuid'
import getDefaultState from './getDefaultState'
import { getList } from './selectors'

const handleLogin = token => {
  sessionStorage.setItem('token', token)
}

const handleLogout = () => {
  sessionStorage.removeItem('token')
}

const updateRelations = (state, name, relations) => ({
  ...state,
  data: {
    ...state.data,
    _relations: {
      ...state.data._relations,
      [name]: relations
    }
  }
})

export const setSchema = (state, action) => ({
  ...state,
  schema: action.schema
})

export const setSidebarOpen = (state, action) => ({
  ...state,
  sidebarOpen: action.sidebarOpen
})

export const setShowImprint = (state, action) => ({
  ...state,
  showImprint: action.showImprint
})

export const setShowVariableNames = (state, action) => ({
  ...state,
  showVariableNames: action.showVariableNames
})

export const setCurrentPage = (state, action) => ({
  ...state,
  currentPage: action.currentPage,
  showValidation: {
    ...state.showValidation,
    [state.currentPage]: true
  },
  showImprint: false
})

export const setData = (state, action) =>
  action.prefix
    ? {
        ...state,
        modalForms: {
          ...state.modalForms,
          [action.prefix]: {
            ...(state.modalForms[action.prefix] || {}),
            data: {
              ...(state.modalForms[action.prefix].data || {}),
              [action.key]: action.value
            }
          }
        }
      }
    : {
        ...state,
        data: {
          ...state.data,
          [action.key]: action.value
        }
      }

export const setLoadingData = (state, action) => ({
  ...state,
  loadingData: action.loadingData
})

export const setAutosave = (state, action) => ({
  ...state,
  autosave: action.autosave
})

export const loadData = (state, action) =>
  action.data
    ? {
        ...state,
        ...action.data,
        loadingData: false,
        autosave: true,
        isLoggedIn: true,
        userName: state.userName
      }
    : state

export const login = (state, action) => {
  handleLogin(action.token)
  return {
    ...state,
    isLoggedIn: true,
    userName: action.userName,
    token: action.token
  }
}

export const logout = (state, action) => {
  handleLogout()
  return {
    ...getDefaultState(),
    autosave: false,
    currentPage: 'anmeldung',
    schema: state.schema
  }
}

export const acceptTerms = (state, action) => ({
  ...state,
  acceptTerms: true
})

export const reset = (state, action) => {
  handleLogout()
  return {
    ...getDefaultState(),
    currentPage: 'start',
    schema: state.schema
  }
}

export const setWorkflowStatus = (state, action) => ({
  ...state,
  workflowStatus: action.workflowStatus
})

export const showValidation = (state, action) => ({
  ...state,
  showValidation: {
    ...state.showValidation,
    [action.pageName]: true
  }
})

export const upsertListEntry = (state, action) => {
  const listName = action.listName
  if (!listName) {
    return state
  }
  const list = getList(listName)(state)
  const hasId = !!action.data?.id
  const objectId = hasId ? action.data.id : generateUuid()
  if (!hasId) {
    list.push({
      ...action.data,
      id: objectId
    })
  } else {
    const index = list.findIndex(({ id }) => id === objectId)
    if (index < 0) {
      list.push(action.data)
    } else {
      list[index] = action.data
    }
  }
  return {
    ...state,
    data: {
      ...state.data,
      _lists: {
        ...state.data._lists,
        [listName]: list
      }
    }
  }
}

export const removeListEntry = (state, action) => {
  const listName = action.listName
  if (!listName) {
    return state
  }
  const list = getList(action.listName)(state)
  return {
    ...state,
    data: {
      ...state.data,
      _lists: {
        ...state.data._lists,
        [listName]: list.filter(({ id }) => id !== action.id)
      }
    }
  }
}

export const addRelation = (state, action) => {
  const relations = (state.data._relations[action.name] || []).filter(
    ([k0, k1]) => !(k0 === action.keys[0] && k1 === action.keys[1])
  )
  return updateRelations(state, action.name, [...relations, action.keys])
}

export const removeRelation = (state, action) => {
  const relations = (state.data._relations[action.name] || []).filter(
    ([k0, k1]) =>
      !(
        (action.keys[1] === null && action.keys[0] === k0) ||
        (action.keys[0] === null && action.keys[1] === k1) ||
        (action.keys[0] === k0 && action.keys[1] === k1)
      )
  )
  return updateRelations(state, action.name, relations)
}

export const addModalForm = (state, action) => ({
  ...state,
  modalForms: {
    [action.name]: {
      data: action.data || {}
    }
  }
})

export const removeModalForm = (state, action) => ({
  ...state,
  modalForms: {}
})
