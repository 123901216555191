import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import classNames from 'classnames'
import de from 'date-fns/locale/de'
import 'react-datepicker/dist/react-datepicker.css'

import ElementHeader from '../ElementHeader'
import t from '../../getText'
import styles from './Timerange.module.scss'

const dateFormat = 'dd.MM.yyyy'

registerLocale('de', de)
setDefaultLocale('de')

const dateValue = value =>
  typeof value === 'string' && value !== '' ? new Date(value) : value

export default ({ data = [], setData, schema, readOnly, labelStart, labelEnd }) => {
  const { name } = schema
  const startId = `${name}_von`
  const endId = `${name}_bis`

  const startDate = dateValue(data[0] || null)
  const endDate = dateValue(data[1] || null)

  const props = id => ({
    id,
    locale: 'de',
    className: 'form-control ' + styles.picker,
    isClearable: true,
    dateFormat,
    onChange: v => {
      if (readOnly) {
        return
      }
      const range = id === startId ? [v, endDate] : [startDate, v]
      const sortedRange =
        range[0] && range[1] && range[1] < range[0]
          ? [range[1], range[0]]
          : range
      setData(sortedRange)
    },
    startDate,
    endDate,
    readOnly,
    autoComplete: 'off'
  })

  return (
    <div
      className={classNames(styles.root, {
        [styles.disabled]: readOnly
      })}
    >
      <ElementHeader schema={schema}/>
      <InputGroup>
        <span>
          <Form.Label htmlFor={startId} className={styles.label}>
            {labelStart || t('formElements.timerange.labelStart')}
          </Form.Label>
          <DatePicker {...props(startId)} selectsStart selected={startDate} />
        </span>
        <span>
          <Form.Label htmlFor={endDate} className={styles.label}>
            {labelEnd || t('formElements.timerange.labelEnd')}
          </Form.Label>
          <DatePicker {...props(endId)} selectsEnd selected={endDate} />
        </span>
      </InputGroup>
    </div>
  )
}
