import objectPath from 'object-path'

import text from './i18n/de'

export default (path, context) => {
  const template = objectPath.get(text, path)
  if (!template) {
    return `[${path}]`
  }
  return typeof template === 'function' ? template(context) : template
}
