import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as FormElements from './FormElements'
import ElementInfo from './ElementInfo'
import evaluate from '../evaluate'

const FormElement = ({ schema, prefix, showValidation }) => {
  const dispatch = useDispatch()

  const { name, visible } = schema

  const { state, data, readOnly, showVariableNames } = useSelector(state => {
    const { data, readOnly, showVariableNames } = state
    return {
      state,
      data: data[name] ? data[name] : undefined,
      readOnly,
      showVariableNames
    }
  })

  if (!evaluate(state, visible, true)) {
    return null
  }

  const required = evaluate(state, schema.required, false)

  const setData = value =>
    dispatch({
      type: 'setData',
      key: name,
      value,
      prefix
    })

  const { formElement } = schema
  const FormElement = FormElements[formElement]
    ? FormElements[formElement]
    : FormElements.Default

  const element = (
    <FormElement
      {...{
        schema: {
          ...schema,
          required
        },
        data,
        setData,
        readOnly,
        prefix,
        showValidation
      }}
    />
  )
  return showVariableNames ? (
    <ElementInfo schema={schema}>{element}</ElementInfo>
  ) : (
    element
  )
}

export default ({ schema, ...rest }) =>
  Array.isArray(schema) ? (
    schema.map(element => {
      const { name } = element
      if (!name) {
        console.log('FormElement name is missing: ', element)
        return null
      }
      return <FormElement key={name} {...rest} schema={element} />
    })
  ) : (
    <FormElement {...rest} schema={schema} />
  )
