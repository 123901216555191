export default () => null
/*import React from 'react'

import styles from './Footer.module.scss'

export default () => (
  <div className={styles.root}>
    <a href="#">Impressum</a>
    <a href="#">Datenschutz</a>
  </div>
)
*/
