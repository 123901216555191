export default {
  api: {
    internalError:
      'Interner Fehler: Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
  },
  login: {
    tokenInvalid: 'Ungültiger Zugangscode',
    registeredAlready: 'Bitte prüfen Sie Ihr E-Mail-Postfach.'
  },
  navigation: {
    prev: 'Zurück',
    next: 'Weiter'
  },
  formElements: {
    timerange: {
      labelStart: 'Von',
      labelEnd: 'bis'
    }
  },
  validation: {
    required: {
      required: 'Dieses Feld muss ausgefüllt werden.',
      matrix: 'Bitte wählen Sie für jede Zeile eine Antwort aus.',
      options: {
        single: 'Bitte wählen Sie eine der folgenden Optionen.',
        multiple: 'Bitte wählen Sie eine oder mehrere der folgenden Optionen.'
      },
      timerange: 'Bitte wählen Sie einen Zeitraum.'
    },
    minLength: ({ minLength = 1 }) =>
      `Bitte geben Sie mindestens ${minLength} Zeichen ein.`,
    maxLength: ({ maxLength = 100 }) =>
      `Bitte begrenzen Sie die Eingabe auf ${maxLength} Zeichen.`,
    dataType: {
      integer: {
        invalid: 'Bitte geben Sie eine Ganzzahl ein.',
        min: ({ min }) =>
          `Bitte geben Sie eine Ganzzahl größer oder gleich ${min} ein.`,
        max: ({ max }) =>
          `Bitte geben Sie eine Ganzzahl kleiner oder gleich ${max} ein.`,
        minMax: ({ min, max }) =>
          `Bitte geben Sie eine Ganzzahl zwischen ${min} und ${max} ein.`
      },
      float: {
        invalid: 'Bitte geben Sie eine Zahl ein.',
        min: ({ min }) =>
          `Bitte geben Sie eine Zahl größer oder gleich ${min} ein.`,
        max: ({ max }) =>
          `Bitte geben Sie eine Zahl kleiner oder gleich ${max} ein.`,
        minMax: ({ min, max }) =>
          `Bitte geben Sie eine Zahl zwischen ${min} und ${max} ein.`
      },
      email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      zip: 'Bitte geben Sie eine Postleitzahl ein.',
      phone:
        'Bitte geben Sie eine Telefonnummer ein.<br/>Mindestens drei Zeichen: Ziffern, Leerzeichen, + und -'
    },
    personal: {
      required: 'Bitte fügen Sie mindestens eine Fachkraft hinzu.',
      assignments:
        'Bitte wählen Sie für jede Fachkraft die Gruppen/Bereiche aus, in denen diese tätig sind.'
    }
  },

  /* do NOT translate this ... */
  foo: { bar: 'baz' }
}
