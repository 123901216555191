import React from 'react'
import { useSelector } from 'react-redux'

import LoginInfo from '../LoginParts/LoginInfo'
import Token from '../LoginParts/Token'
import Register from '../LoginParts/Register'
import styles from './Login.module.scss'

export default () => {
  const isLoggedIn = useSelector(({ isLoggedIn }) => isLoggedIn)

  return (
    <div className={styles.root}>
      {isLoggedIn ? (
        <LoginInfo />
      ) : (
        <>
          <Token />
          <Register />
        </>
      )}
    </div>
  )
}
