import React from 'react'
import classNames from 'classnames'

import FormElement from '../FormElement'
import Info from './Info'
import styles from './Page.module.scss'

const hasRequired = schema =>
  schema?.required
    ? true
    : schema?.children?.reduce((acc, child) => acc || hasRequired(child), false)

export default ({ schema, ...rest }) => {
  const { info, sticky, requiredHint, children } = schema
  return (
    <div className={classNames(styles.root, { [styles.sticky]: sticky })}>
      <Info info={info} />
      <div className={styles.wrap}>
        <FormElement {...rest} schema={children} />
      </div>
      {requiredHint && hasRequired(schema) && <div>* Pflichtfeld</div>}
    </div>
  )
}
