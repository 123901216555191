import React from 'react'
import { Modal, Button } from 'react-bootstrap'

export default ({
  title,
  caption,
  show,
  setShow,
  onConfirmed,
  variant = 'danger',
  children
}) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShow(false)}>
        Abbrechen
      </Button>
      <Button variant={variant} onClick={onConfirmed}>
        {caption}
      </Button>
    </Modal.Footer>
  </Modal>
)
