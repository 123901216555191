import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import evaluate from '../../evaluate'
import { getList } from '../../selectors'
import PersonsGroupsMatrix from '../PersonsGroupsMatrix'
import PersonForm from '../PersonForm'
import Feedback from '../Feedback'

const listName = 'fachkraefte'

export default ({
  data,
  schema: { name, info, withMatrix = true },
  setData,
  infoRelation,
  readOnly,
  showValidation
}) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const fachkraefte = useSelector(getList(listName))
  const gruppen = useSelector(getList('gruppen'))

  const upsert = data => dispatch({ type: 'upsertListEntry', listName, data })
  const remove = id => {
    dispatch({ type: 'removeListEntry', listName, id })
    dispatch({
      type: 'removeRelation',
      name: 'fachkraefte-gruppen',
      keys: [id, null]
    })
  }

  const showMatrix = evaluate(withMatrix, state, true)

  return (
    <>
      <PersonForm
        persons={fachkraefte}
        addPerson={upsert}
        readOnly={readOnly}
      />
      {showMatrix ? (
        <>
          <PersonsGroupsMatrix
            persons={fachkraefte}
            groups={gruppen}
            data={data}
            setData={setData}
            deletePerson={remove}
            readOnly={readOnly}
          />
          <Feedback name={name} showValidation={showValidation} />
        </>
      ) : null}
    </>
  )
}
