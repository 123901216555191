import React from 'react'
import { Alert } from 'react-bootstrap'

import styles from './Message.module.scss'

export default ({ message }) =>
  message && (
    <Alert className={styles.root} variant="danger">
      {message}
    </Alert>
  )
