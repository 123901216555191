import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import styles from './Imprint.module.scss'
import Contact from './Contact'

const Mailto = ({ email }) => <a href={`mailto:${email}`}>{email}</a>

export const ImprintToggle = () => {
  const dispatch = useDispatch()
  const showImprint = useSelector(({ showImprint }) => showImprint)

  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        dispatch({ type: 'setShowImprint', showImprint: !showImprint })
      }}
      className={classNames(styles.toggle, {
        [styles.active]: showImprint,
      })}
    >
      Impressum & Kontakt
    </button>
  )
}

export const Imprint = () => (
  <>
    <Contact />
    <div className={styles.contents}>
      <h3>Impressum</h3>

      <p>
        pädquis Stiftung b. R.
        <br />
        Kooperationsinstitut der Pädagogischen Hochschule Schwäbisch Gmünd
      </p>
      <p>
        Ordensmeisterstraße 15-16 (2.OG)
        <br />
        12099 Berlin
        <br />
        Deutschland
      </p>
      <p>
        Telefon: +49 30 720061-11
        <br />
        Fax: +49 30 720061-161
        <br />
        E-Mail: <Mailto email="info@paedquis.de" />
      </p>

      <h4>Vorstand</h4>
      <p>
        Prof. Dr. Katharina Kluczniok (Vors.)
        <br />
        Moritz Bolle
        <br />
        Prof. Dr. Stefan Faas
      </p>

      <h4>Zuständige Aufsichtsbehörde</h4>
      <p>
        Senatsverwaltung für Justiz, Verbraucherschutz und Antidiskriminierung
        des Landes Berlin
        <br />
        Stiftungsaufsicht – II D 2
        <br />
        Salzburger Straße 21–25
        <br />
        10825 Berlin
        <br />
        Urkunde Nr.: 3416/1304/2
        <br />
        Eingetragen am: 21.02.2020
        <br />
        Steuernummer: 27/028/41531
        <br />
        Finanzamt für Körperschaften I Berlin
      </p>
      <p>
        <a href="https://www.paedquis.de/datenschutz/" target="paedquis">Datenschutz</a>
        <br />
        <a href="https://www.paedquis.de/kontakt/" target="paedquis">Kontakt</a>
      </p>
    </div>
  </>
)
