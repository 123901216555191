import axios from 'axios'

import getDownload from './getDownload'

const apiUrl = process.env.REACT_APP_API_URL || '/api'

export const loadByUserName = async userId => {
  const res = await axios({
    method: 'get',
    url: `${apiUrl}/formdata/loadByUserName/${userId}`
  })
  return res?.data ? res.data : null
}

export const register = data => axios.post(`${apiUrl}/formdata/register`, data)

export const loadByToken = token =>
  axios.get(`${apiUrl}/formdata/loadByToken/${token}`)

export const saveData = (data, callback) => {
  axios({
    method: 'post',
    url: `${apiUrl}/formdata/save`,
    data
  })
    .then(({ status }) => {
      callback(status === 200)
    })
    .catch(e => {
      callback(false)
    })
}

export const finalize = token =>
  axios.post(`${apiUrl}/formdata/finalize/${token}`)

export const downloadPdf = (token, setStatus = () => null) =>
  getDownload(`${apiUrl}/formdata/pdf/${token}`, setStatus)
