export default ({ data }, schema, required) => {
  const { name } = schema
  const value = data[name]
  const countAnswered = value
    ? schema.items.reduce(
        (acc, { name }) => (typeof value[name] === 'undefined' ? acc : acc + 1),
        0
      )
    : 0

  return required && countAnswered < schema.items.length
    ? 'required.matrix'
    : null
}
