import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaPredicate } from 'react-media-hook'
import useDocumentTitle from '@rehooks/document-title'
import Sidebar from 'react-sidebar'
import Modal from 'react-bootstrap/Modal'
import useEventListener from '@use-it/event-listener'

import { loadByToken } from '../api'
import Menu from './Menu'
import { ImprintToggle, Imprint } from './Imprint'
import Navigation from './Navigation'
import Header from './Header'
import Footer from './Footer'
import Finalize from './Finalize'
import FormElement from './FormElement'
import Spinner from './Spinner'
import styles from './AutoForm.module.scss'
import burger from './burger.svg'
import evaluate from '../evaluate'

const getPageSchema = (schema, pageName) =>
  schema.children.find(({ name }) => name === pageName)

const sidebarStyles = {
  sidebar: {
    zIndex: 10
  },
  overlay: {
    zIndex: 9
  }
}

export default ({ schema, uuid }) => {
  const dispatch = useDispatch()
  const widerThan400 = useMediaPredicate('(min-width: 400px)')
  const state = useSelector(state => state)
  const {
    sidebarOpen,
    showImprint,
    showVariableNames,
    currentPage,
    loadingData
  } = useSelector(
    ({
      sidebarOpen,
      showImprint,
      showVariableNames,
      currentPage,
      loadingData
    }) => ({
      sidebarOpen,
      showImprint,
      showVariableNames,
      currentPage,
      loadingData
    })
  )

  const currentPageSchema = getPageSchema(schema, currentPage)
  const currentPageTitle = evaluate(state, currentPageSchema.title, '')

  const pageTitle = currentPageTitle
    ? `${schema.pageTitle} – ${currentPageTitle}`
    : schema.pageTitle
  useDocumentTitle(pageTitle)

  useEventListener('keydown', e => {
    // SHIFT + ALT + i: Toggle IDs
    if (e.altKey && e.shiftKey && e.which === 73) {
      e.preventDefault()
      dispatch({
        type: 'setShowVariableNames',
        showVariableNames: !showVariableNames
      })
    }
  })

  const setSidebarOpen = sidebarOpen =>
    dispatch({ type: 'setSidebarOpen', sidebarOpen })

  useEffect(() => {
    dispatch({ type: 'setSchema', schema })
  }, [dispatch, schema])

  useEffect(() => {

    const load = async token => {
      dispatch({ type: 'setLoadingData', loadingData: true })
      loadByToken(token)
        .then(({ data, status }) => {
          if (status === 200) {
            const { userName, token } = data
            dispatch({ type: 'login', userName, token })
            dispatch({ type: 'loadData', data })
          } else {
            //
            console.log('loadByToken not 200')
          }
        })
        .catch(e => {
          console.log('loadByToken catch')
        })

    }

    const token = sessionStorage.getItem('token')
    if (token) {
      load(token)
    }
  }, [dispatch])

  return (
    <div className={styles.root}>
      <button
        className={styles.toggle}
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <img
          src={burger}
          alt="Inhaltsverzeichnis"
          title="Menü ein-/ausblenden"
        />
      </button>
      <Sidebar
        touch={true}
        docked={sidebarOpen && widerThan400}
        sidebar={
          <>
            <Menu title={schema.title} />
            <ImprintToggle />
          </>
        }
        open={sidebarOpen}
        onSetOpen={setSidebarOpen}
        shadow={false}
        sidebarClassName={styles.sidebar}
        styles={sidebarStyles}
      >
        <Modal show={loadingData} animation={false} className={styles.loadingModal}>
          <Modal.Body>
            Ihre Daten werden geladen…
            <Spinner active={true} />
          </Modal.Body>
        </Modal>
        <div className={styles.wrap}>
          <Header
            title={
              showImprint ? 'Impressum & Kontakt' : currentPageTitle
            }
            sidebarOpen={sidebarOpen}
          />
          <Finalize />
          <main className={styles.main}>
            <div className={styles.page}>
              {showImprint ? (
                <Imprint schema={schema.imprint} />
              ) : (
                currentPageSchema && <FormElement schema={currentPageSchema} />
              )}
            </div>
          </main>
          <div className={styles.navigation}>
            <Navigation />
          </div>
          <Footer />
        </div>
      </Sidebar>
    </div>
  )
}
