export default {
  name: 'strukturfragebogen',
  formElement: 'Form',
  title: 'Struktur­fragebogen',
  pageTitle: 'pädquis',
  exportName: 'einrichtung',
  relations: {
    'fachkraefte-gruppen': ['fachkraefte_uuid', 'gruppen_uuid']
  },
  version: 1,
  children: [
    {
      name: 'start',
      formElement: 'Page',
      title: 'Start',
      embedPageTitle: false,
      print: false,
      children: [
        {
          name: 'typ',
          formElement: 'Variable',
          expression: ({ typ }, { location }) => {
            return typeof typ === 'undefined' &&
              typeof location?.search?.ktp !== 'undefined'
              ? 'ktp'
              : typ
          }
        },
        {
          name: 'start',
          formElement: 'Workflow',
          byStatus: {
            eingabeEinrichtung: [
              {
                name: 'eingabeEinrichtung:',
                formElement: 'Html',
                html: ({ typ }) =>
                  typ === 'ktp'
                    ? '<p>Sehr geehrte Kindertagespflegeperson,</p><p>wir freuen uns auf die Zusammenarbeit bei der Evaluation Ihrer Kindertagespflege. Für eine gute Planung benötigen wir aktuelle Angaben zu Ihrer Kindertagespflege. Wir bitten Sie daher, den folgenden Strukturfragebogen auszufüllen.</p><p>Hier haben Sie auch die Möglichkeit, Wunschtermine für Ihre Kindertagespflege anzugeben. Wir bemühen uns, diese zu berücksichtigen und umzusetzen.</p><p>Nach Erhalt Ihrer Angaben melden wir uns telefonisch zeitnah bei Ihnen zurück, um die konkreten Termine und individuellen Absprachen für Ihre Kindertagespflege zu besprechen und zu planen.</p><p>Wir bedanken uns herzlich für Ihre Unterstützung!</p><p>Mit freundlichen Grüßen</p><p>Ihr pädquis Stiftung – Team Evaluation</p>'
                    : '<p>Sehr geehrte pädagogische Leitung,</p><p>wir freuen uns auf die Zusammenarbeit bei der Evaluation Ihrer Einrichtung. Für eine gute Planung benötigen wir aktuelle Angaben zu Ihrer Einrichtung. Wir bitten Sie daher, den folgenden Strukturfragebogen auszufüllen.</p><p>Hier haben Sie auch die Möglichkeit, Wunschtermine für Ihre Einrichtung anzugeben. Wir bemühen uns, diese zu berücksichtigen und umzusetzen.</p><p>Nach Erhalt Ihrer Angaben melden wir uns telefonisch zeitnah bei Ihnen zurück, um die konkreten Termine und individuellen Absprachen für Ihre Einrichtung zu besprechen und zu planen.</p><p>Wir bedanken uns herzlich für Ihre Unterstützung!</p><p>Mit freundlichen Grüßen</p><p>Ihr pädquis Stiftung – Team Evaluation</p>'
              }
            ],
            eingabeEinrichtungAbgeschlossen: [
              {
                name: 'eingabeEinrichtungAbgeschlossen:',
                formElement: 'Html',
                html:
                  '<p><strong>Sie haben die Bearbeitung abgeschlossen.</strong></p><p>Wir melden uns in Kürze, um Ihre individuellen Wünsche zu besprechen und die Evaluation gemeinsam zu planen.</p>'
              }
            ]
          }
        }
      ]
    },
    {
      name: 'anmeldung',
      formElement: 'Page',
      title: 'Anmeldung',
      print: false,
      children: [
        {
          name: 'login',
          formElement: 'Login'
        }
      ]
    },
    {
      name: 'traeger',
      formElement: 'Page',
      title: ({ data: { typ } }) =>
        typ === 'ktp' ? 'Träger der Kindertagespflege' : 'Träger',
      titleShort: 'Träger',
      children: [
        {
          name: 'traeger_adresse',
          formElement: 'Section',
          children: [
            {
              name: 'tgName',
              formElement: 'Textinput',
              minLength: 3,
              label: 'Name des Trägers',
              required: true
            },
            {
              name: 'tgStrasse',
              formElement: 'Textinput',
              label: 'Straße, Hausnr.',
              required: true
            },
            {
              name: 'tgPlz',
              formElement: 'Textinput',
              dataType: 'zip',
              label: 'PLZ',
              required: true
            },
            {
              name: 'tgOrt',
              formElement: 'Textinput',
              label: 'Ort',
              required: true
            }
          ]
        },
        {
          name: 'ktpDetails',
          formElement: 'Section',
          visible: ({ data: { typ } }) => typ === 'ktp',
          children: [
            {
              name: 'ktpGross',
              label: 'Handelt es sich um eine Großtagespflege?',
              formElement: 'Options',
              scalar: true,
              inline: true,
              required: ({ data: { typ } }) => typ === 'ktp',
              options: [
                {
                  value: 'nein',
                  exportValue: 0,
                  label: 'Nein'
                },
                {
                  value: 'ja',
                  exportValue: 1,
                  label: 'Ja'
                }
              ]
            },
            {
              name: 'ktpFreiAng',
              label:
                'Sind Sie freiberuflich tätig oder bei einem Träger angestellt?',
              formElement: 'Options',
              scalar: true,
              inline: true,
              required: ({ data: { typ } }) => typ === 'ktp',
              options: [
                {
                  value: 'freiberuflich',
                  label: 'Freiberuflich'
                },
                {
                  value: 'angestellt',
                  label: 'Angestellt'
                }
              ]
            }
          ]
        },
        {
          name: 'an',
          formElement: 'Section',
          title: 'Ansprechperson des Trägers',
          info: ({ data: { typ } }) =>
            '<p>Bitte geben Sie hier die Kontaktdaten der für Ihre ' +
            (typ === 'ktp' ? 'Kindertagespflege' : 'Einrichtung') +
            ' <strong>seitens des Trägers</strong> zuständigen Ansprechperson ein.</p>',
          children: [
            {
              name: 'tgAnrede',
              formElement: 'Options',
              dropdown: true,
              label: 'Anrede',
              options: [
                {
                  value: 'frau',
                  label: 'Frau'
                },
                {
                  value: 'herr',
                  label: 'Herr'
                }
              ]
            },
            {
              name: 'tgTitel',
              formElement: 'Textinput',
              label: 'Titel'
            },
            {
              name: 'tgVorname',
              formElement: 'Textinput',
              label: 'Vorname',
              required: true
            },
            {
              name: 'tgNachname',
              formElement: 'Textinput',
              label: 'Nachname',
              required: true
            },
            {
              name: 'tgEmail',
              formElement: 'Textinput',
              dataType: 'email',
              label: 'E-Mail',
              required: true
            },
            {
              name: 'tgTel',
              formElement: 'Textinput',
              dataType: 'phone',
              label: 'Telefon',
              required: true
            },
            {
              name: 'tgFax',
              formElement: 'Textinput',
              dataType: 'phone',
              label: 'Fax'
            }
          ]
        }
      ]
    },
    {
      name: 'einrichtung',
      formElement: 'Page',
      title: ({ data: { typ } }) =>
        typ === 'ktp' ? 'Kindertagespflege' : 'Einrichtung',
      children: [
        {
          name: 'einrichtung_a',
          formElement: 'Section',
          children: [
            {
              name: 'name',
              formElement: 'Textinput',
              label: ({ data: { typ } }) =>
                typ === 'ktp'
                  ? 'Name der Kindertagespflege'
                  : 'Name der Einrichtung',
              required: true
            },
            {
              name: 'zusatz',
              formElement: 'Textinput',
              label: 'Zusatz'
            },
            {
              name: 'strasse',
              formElement: 'Textinput',
              label: 'Straße, Hausnr.',
              required: true
            },
            {
              name: 'plz',
              formElement: 'Textinput',
              dataType: 'zip',
              label: 'PLZ',
              required: true
            },
            {
              name: 'ort',
              formElement: 'Textinput',
              label: 'Ort',
              required: true
            },
            {
              name: 'telefon',
              formElement: 'Textinput',
              dataType: 'phone',
              label: 'Telefon',
              required: true
            },
            {
              name: 'fax',
              formElement: 'Textinput',
              dataType: 'phone',
              label: 'Fax'
            },
            {
              name: 'email',
              formElement: 'Textinput',
              dataType: 'email',
              label: 'E-Mail',
              required: true
            },
            {
              name: 'homepage',
              formElement: 'Textinput',
              label: 'Homepage'
            }
          ]
        }
      ]
    },
    {
      name: 'einrichtung_ansprechpersonen',
      formElement: 'Page',
      title: ({ data: { typ } }) =>
        typ === 'ktp'
          ? 'Ansprechperson der Kindertagespflege'
          : 'Ansprechpersonen der Einrichtung',
      titleShort: 'Ansprechpersonen',
      info: ({ data: { typ } }) =>
        '<p>Bitte fügen Sie mindestens eine <strong>Ansprechperson Ihrer ' +
        (typ === 'ktp' ? 'Kindertagespflege' : 'Einrichtung') +
        '</strong> hinzu.</p>',
      children: [
        {
          name: 'ansprechpersonen',
          formElement: 'List',
          minEntries: 1,
          maxEntries: 6,
          captions: {
            add: 'Ansprechperson hinzufügen',
            edit: 'Ansprechperson bearbeiten',
            create: 'Neue Ansprechperson',
            deleteTitle: 'Ansprechperson löschen',
            deleteConfirm: ({ vorname, nachname }) =>
              `Sind Sie sicher, dass die Ansprechperson ${vorname} ${nachname} gelöscht werden soll?`
          },
          columns: ({ data: { typ } }) =>
            typ === 'ktp'
              ? {
                  index: 'Nr',
                  vorname: 'Vorname',
                  nachname: 'Nachname'
                }
              : {
                  index: 'Nr',
                  vorname: 'Vorname',
                  nachname: 'Nachname',
                  funktion: 'Funktion'
                },

          children: [
            {
              name: 'anrede',
              formElement: 'Options',
              dropdown: true,
              label: 'Anrede',
              options: [
                {
                  value: 'frau',
                  label: 'Frau'
                },
                {
                  value: 'herr',
                  label: 'Herr'
                }
              ]
            },
            {
              name: 'titel',
              formElement: 'Textinput',
              label: 'Titel'
            },
            {
              name: 'vorname',
              formElement: 'Textinput',
              label: 'Vorname',
              required: true
            },
            {
              name: 'nachname',
              formElement: 'Textinput',
              label: 'Nachname',
              required: true
            },
            {
              name: 'funktion',
              formElement: 'Options',
              label: 'Funktion',
              required: ({ data: { typ } }) => typ !== 'ktp',
              visible: ({ data: { typ } }) => typ !== 'ktp',
              options: [
                {
                  value: 'paed',
                  label: 'Pädagogische Leitung'
                },
                {
                  value: 'stellv',
                  label: 'Stellvertretende Leitung'
                },
                {
                  value: 'kom',
                  label: 'Kommissarische Leitung'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: 'struktur',
      formElement: 'Page',
      title: ({ data: { typ } }) =>
        typ === 'ktp'
          ? 'Struktur der Kindertagespflege'
          : 'Einrichtungsstruktur',
      titleShort: 'Struktur',
      children: [
        {
          name: 'art',
          formElement: 'Options',
          label: 'Art der Einrichtung',
          required: ({ data: { typ } }) => typ !== 'ktp',
          visible: ({ data: { typ } }) => typ !== 'ktp',
          options: [
            {
              value: 'ganz',
              label:
                'Ganztagseinrichtung<span class="labelDetail">Wenigstens 8 h geöffnet und Betreuung der Kinder über Mittag</span>'
            },
            {
              value: 'vor',
              label:
                'Halbtagseinrichtung (Vormittag)<span class="labelDetail">Nur vormittags geöffnet</span>'
            },
            {
              value: 'nach',
              label:
                'Halbtageseinrichtung (Nachmittag)<span class="labelDetail">Nur nachmittags geöffnet</span>'
            },
            {
              value: 'mitt',
              label:
                'Halbtageseinrichtung (Mittagsschließung)<span class="labelDetail">Vormittags und nachmittags geöffnet, mit Schließung über Mittag</span>'
            }
          ]
        },
        {
          name: 'betreuungszeiten',
          label: 'Betreuungszeiten in der Woche',
          labelOnTop: true,
          formElement: 'Textinput',
          hint:
            'z. B. „Montag bis Donnerstag, 8 bis 14:30 Uhr, Freitag 8-12 Uhr“',
          multiline: true,
          required: ({ data: { typ } }) => typ === 'ktp',
          visible: ({ data: { typ } }) => typ === 'ktp'
        },
        {
          name: 'kinder_anzahl',
          formElement: 'Section',
          title: 'Kinder',
          children: [
            {
              name: 'einrNPlaetzeErlaub',
              formElement: 'Textinput',
              label: 'Anzahl der zulässigen Plätze nach Betriebserlaubnis',
              dataType: 'integer',
              min: 1,
              required: true
            },
            {
              name: 'einrNKinder',
              formElement: 'Textinput',
              label: 'Anzahl der aktuell angemeldeten Kinder',
              dataType: 'integer',
              min: 1,
              required: true
            },
            {
              name: 'einrNKinderBehin',
              formElement: 'Textinput',
              label:
                'Anzahl der aktuell angemeldeten Kinder mit anerkanntem erhöhten Förderbedarf',
              dataType: 'integer',
              max: 200,
              required: true
            },
            {
              name: 'kinderMax',
              formElement: 'Textinput',
              label: 'Maximal gleichzeitig anwesende Kinder',
              dataType: 'integer',
              min: 0,
              max: 200,
              required: ({ data: { typ } }) => typ === 'ktp',
              visible: ({ data: { typ } }) => typ === 'ktp'
            }
          ]
        },
        {
          name: 'sectionAlter',
          formElement: 'Section',
          title: 'Altersspanne der Kinder in der Gruppe',
          hint: 'Altersangaben in Jahren, z.\u00A0B. „3,5“ Jahre',
          visible: ({ data: { typ } }) => typ === 'ktp',
          children: [
            {
              name: 'alterVon',
              label: 'Von',
              formElement: 'Textinput',
              dataType: 'decimal',
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'alterBis',
              label: 'Bis',
              formElement: 'Textinput',
              dataType: 'decimal',
              required: ({ data: { typ } }) => typ === 'ktp'
            }
          ]
        },
        {
          name: 'kinderAgSection',
          title: 'Anzahl der Kinder nach Altersgruppen',
          visible: ({ data: { typ } }) => typ === 'ktp',
          formElement: 'Section',
          children: [
            {
              name: 'kinderAg6',
              formElement: 'Textinput',
              label: 'Jünger als 6 Monate',
              dataType: 'integer',
              min: 0,
              max: 900,
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'kinderAg6_12',
              formElement: 'Textinput',
              label: '6 bis jünger als 12 Monate',
              dataType: 'integer',
              min: 0,
              max: 900,
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'kinderAg12_24',
              formElement: 'Textinput',
              label: '12 bis jünger als 24 Monate',
              dataType: 'integer',
              min: 0,
              max: 900,
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'kinderAg24_36',
              formElement: 'Textinput',
              label: '24 bis jünger als 36 Monate',
              dataType: 'integer',
              min: 0,
              max: 900,
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'kinderAg36_48',
              formElement: 'Textinput',
              label: '36 bis jünger als 48 Monate',
              dataType: 'integer',
              min: 0,
              max: 900,
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'kinderAg48_60',
              formElement: 'Textinput',
              label: '48 bis jünger als 60 Monate',
              dataType: 'integer',
              min: 0,
              max: 900,
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'kinderAg60_',
              formElement: 'Textinput',
              label: 'Ab 60 Monate',
              dataType: 'integer',
              min: 0,
              max: 900,
              required: ({ data: { typ } }) => typ === 'ktp'
            }
          ]
        },
        {
          name: 'offen',
          formElement: 'Options',
          label: 'Struktur der Einrichtung',
          multiple: true,
          required: ({ data: { typ } }) => typ !== 'ktp',
          visible: ({ data: { typ } }) => typ !== 'ktp',
          options: [
            {
              value: 'einrStrukturGru',
              label: 'Gruppenarbeit'
            },
            {
              value: 'einrStrukturGruUe',
              label: 'Gruppenübergreifende Arbeit'
            },
            {
              value: 'einrStrukturOffenOhne',
              label: 'Offene Arbeit ohne Bezugsgruppen'
            },
            {
              value: 'einrStrukturOffenMit',
              label: 'Offene Arbeit mit Bezugsgruppen'
            }
          ]
        },
        {
          name: 'einrStrukturGruGesamt',
          formElement: 'Textinput',
          required: ({ data: { typ } }) => typ !== 'ktp',
          visible: ({ data: { typ } }) => typ !== 'ktp',
          label: 'Wie viele Gruppen/Bereiche gibt es in Ihrer Einrichtung?',
          dataType: 'integer',
          min: 1,
          max: 200
        },
        {
          name: 'section_tagesablauf',
          formElement: 'Section',
          title: 'Tagesablauf',
          info: ({ data: { typ } }) =>
            '<p>Bitte geben Sie hier grob Ihren Tagesablauf an, damit wir diesen bei der zeitlichen Planung der Evaluation berücksichtigen können (z. B. Bringzeit der Kinder, Beginn der Kernzeit, Frühstück, Mittagessen)</p>' +
            (typ === 'ktp'
              ? ''
              : '<p>Sollte der Tagesablauf in Ihrer Einrichtung für die verschiedenen Bereiche (Krippe, Kindergarten, ggf. Hort) unterschiedlich sein, geben Sie gerne den groben Tagesablauf für die jeweiligen Bereiche an.</p>'),
          children: [
            {
              name: 'tagesablauf',
              formElement: 'Textinput',
              label: 'Tagesablauf',
              labelOnTop: true,
              required: true,
              multiline: true
            }
          ]
        },
        {
          name: 'sectionRaeume',
          formElement: 'Section',
          title: 'Räumlichkeiten der Kindertagespflege',
          visible: ({ data: { typ } }) => typ === 'ktp',
          children: [
            {
              name: 'raGruppenbezogenAnzahl',
              formElement: 'Textinput',
              label: 'Anzahl der Räume',
              dataType: 'integer',
              min: 0,
              required: ({ data: { typ } }) => typ === 'ktp'
            },
            {
              name: 'raGruppenbezogenQm',
              formElement: 'Textinput',
              label: 'Größe der Räume',
              postfix: 'm²',
              dataType: 'decimal',
              min: 0,
              required: ({ data: { typ } }) => typ === 'ktp'
            }
          ]
        }
      ]
    },
    {
      name: 'einrichtung_gruppen',
      formElement: 'Page',
      title: 'Gruppen und Bereiche',
      titleShort: 'Gruppen/Bereiche',
      visible: ({ data: { typ } }) => typ !== 'ktp',
      children: [
        {
          name: 'gruppen',
          formElement: 'List',
          info:
            '<p>Bitte tragen Sie hier sämtliche Gruppen und Bereiche Ihrer Einrichtung ein.</p>',
          captions: {
            add: 'Gruppe/Bereich hinzufügen',
            edit: 'Gruppe/Bereich bearbeiten',
            create: 'Neue Gruppe/neuer Bereich',
            deleteTitle: 'Gruppe/Bereich löschen',
            deleteConfirm: ({ name }) =>
              `Sind Sie sicher, dass die Gruppe/der Bereich ${name} gelöscht werden soll?`
          },
          columns: {
            index: 'Nr',
            name: 'Name',
            typ: 'Gruppe/Bereich',
            kidGesamt: 'Kinder'
          },
          required: ({ data: { typ } }) => typ !== 'ktp',
          minEntries: 1,
          maxEntries: 18,
          children: [
            {
              name: 'typ',
              formElement: 'Options',
              required: true,
              label: 'Gruppe oder Bereich?',
              dropdown: true,
              options: [
                {
                  value: 'gruppe',
                  label: 'Gruppe'
                },
                {
                  value: 'bereich',
                  label: 'Bereich'
                }
              ]
            },

            {
              name: 'name',
              formElement: 'Textinput',
              required: true,
              label: 'Name der Gruppe/des Bereichs'
            },
            {
              name: 'altersstruktur',
              formElement: 'Options',
              required: true,
              label: 'Altersstruktur',
              dropdown: true,
              options: [
                {
                  value: 'krippe',
                  label: 'Krippe'
                },
                {
                  value: 'kiga',
                  label: 'Kindergarten'
                },
                {
                  value: 'hort',
                  label: 'Hort'
                },
                {
                  value: 'ag04',
                  label: 'Altersübergreifend 0-4'
                },
                {
                  value: 'ag06',
                  label: 'Altersübergreifend 0-6'
                },
                {
                  value: 'ag24',
                  label: 'Altersübergreifend 2-4'
                },
                {
                  value: 'ag26',
                  label: 'Altersübergreifend 2-6'
                },
                {
                  value: 'pflege',
                  label: 'Kindertagespflege/Tagesfamilie'
                }
              ]
            },
            {
              name: 'struktur',
              formElement: 'Options',
              required: true,
              label: 'Struktur der Gruppe/des Bereichs',
              dropdown: true,
              options: [
                {
                  value: 'gruppenarbeit',
                  label: 'Gruppenarbeit'
                },
                {
                  value: 'gruppenuebergreifend',
                  label: 'Gruppenübergreifend'
                },
                {
                  value: 'offenMit',
                  label: 'Offene Arbeit mit Bezugsperson'
                },
                {
                  value: 'offenOhne',
                  label: 'Offene Arbeit ohne Bezugsperson'
                }
              ]
            },
            {
              name: 'teiloffenMit',
              formElement: 'Textinput',
              required: true,
              label:
                'Mit welcher Gruppe/welchem Bereich arbeiten Sie zusammen?',
              multiline: true
            },
            {
              name: 'kidGesamt',
              formElement: 'Textinput',
              required: true,
              label: 'Anzahl der Kinder in der Gruppe/im Bereich',
              dataType: 'integer',
              min: 1,
              max: 200
            },
            {
              name: 'kidBehin',
              formElement: 'Textinput',
              required: true,
              label:
                'Anzahl der aktuell angemeldeten Kinder mit anerkanntem erhöhten Förderbedarf',
              dataType: 'integer',
              min: 0,
              max: 200
            },
            {
              name: 'kidUnter3J',
              formElement: 'Textinput',
              required: true,
              label: 'Anzahl der Kinder unter 3 Jahre',
              dataType: 'integer',
              min: 0,
              max: 200
            },
            {
              name: 'kid1J',
              formElement: 'Textinput',
              required: true,
              label: 'Anzahl der Einjährigen',
              dataType: 'integer',
              min: 0,
              max: 200
            },
            {
              name: 'kid2J',
              formElement: 'Textinput',
              required: true,
              label: 'Anzahl der Zweijährigen',
              dataType: 'integer',
              min: 0,
              max: 200
            },
            {
              name: 'kid2JAlter',
              formElement: 'Textinput',
              label: 'Alter der Zweijährigen (Angabe Monat/Jahr)',
              multiline: true
            },
            {
              name: 'raeumeAnzahl',
              formElement: 'Textinput',
              required: true,
              label: 'Anzahl der Räume',
              dataType: 'integer',
              min: 0,
              max: 200
            },
            {
              name: 'raeumeInfo',
              formElement: 'Textinput',
              required: true,
              label: 'Art der Räume',
              multiline: true
            }
          ]
        },
        {
          name: 'gruppenAnzahl',
          formElement: 'Variable',
          expression: ({ _lists: { gruppen = [] } }) => gruppen.length
        },
        {
          name: 'altersstrukturen',
          formElement: 'Variable',
          expression: ({ _lists: { gruppen = [] } }) =>
            gruppen.reduce(
              (acc, { altersstruktur }) =>
                !altersstruktur?.length || acc.includes(altersstruktur[0])
                  ? acc
                  : [...acc, altersstruktur[0]],
              []
            )
        }
      ]
    },
    {
      name: 'fachkraefte',
      formElement: 'Page',
      visible: ({ data: { typ } }) => typ !== 'ktp',
      title: 'Pädagogische Fachkräfte',
      titleShort: 'Fachkräfte',
      info:
        '<p>Bitte geben Sie Vor- und Nachnamen der pädagogischen Fachkräfte ein und wählen Sie die Gruppen/Bereiche aus, in denen diese tätig sind.</p>',
      children: [
        {
          name: 'fachkraefte',
          formElement: 'Personal',
          listNames: ['fachkraefte', 'gruppen'],
          relationName: 'fachkraefte-gruppen',
          withMatrix: ({ data: { typ } }) => typ !== 'ktp',
          info:
            '<p>Bitte wählen Sie für jede Fachkraft die Gruppen/Bereiche aus, in denen diese tätig sind.</p>',
          form: [
            {
              name: 'anrede',
              formElement: 'Options',
              dropdown: true,
              label: 'Anrede',
              options: [
                {
                  value: 'frau',
                  label: 'Frau'
                },
                {
                  value: 'herr',
                  label: 'Herr'
                }
              ]
            },
            {
              name: 'vorname',
              formElement: 'Textinput',
              label: 'Vorname',
              required: true
            },
            {
              name: 'nachname',
              formElement: 'Textinput',
              label: 'Nachname',
              required: true
            }
          ]
        }
      ]
    },
    {
      name: 'termine',
      formElement: 'Page',
      title: 'Wunschtermine',
      children: [
        {
          name: 'wunschtermine',
          formElement: 'Section',
          info:
            '<p>Bitte beachten Sie, dass hier angegebene Wunschtermine erst nach unserer Zusage gültig sind.</p>',
          children: [
            {
              name: 'wunT1',
              formElement: 'Timerange',
              label: '1. Termin',
              required: true
            },
            {
              name: 'wunT2',
              formElement: 'Timerange',
              label: '2. Termin'
            },
            {
              name: 'wunT3',
              formElement: 'Timerange',
              label: '3. Termin'
            }
          ]
        },
        {
          name: 'wunT',
          formElement: 'Matrix',
          title: 'Wochentage',
          info: '<p>An welchen Wochentagen sind Termine möglich?</p>',
          required: true,
          options: [
            {
              value: 'ja',
              label: 'Ja'
            },
            {
              value: 'nein',
              label: 'Nein'
            },
            {
              value: 'eventuell',
              label: 'Eventuell'
            }
          ],
          items: [
            {
              name: 'mo',
              label: 'Montag'
            },
            {
              name: 'di',
              label: 'Dienstag'
            },
            {
              name: 'mi',
              label: 'Mittwoch'
            },
            {
              name: 'do',
              label: 'Donnerstag'
            },
            {
              name: 'fr',
              label: 'Freitag'
            }
          ]
        },
        {
          name: 'wunTAnmerk',
          formElement: 'Textinput',
          labelOnTop: true,
          label: 'Anmerkungen',
          multiline: true
        }
      ]
    },
    {
      name: 'seiteKonzeption',
      title: 'Konzeption',
      formElement: 'Page',
      visible: ({ data: { typ } }) => typ === 'ktp',
      children: [
        {
          name: 'konzeptionMvorhanden',
          label:
            'Gibt es in Ihrer Kindertagespflege schriftliche Informationen oder Materialien, die den Eltern bei der Anmeldung mitgegeben werden?',
          formElement: 'Options',
          scalar: true,
          inline: true,
          required: ({ data: { typ } }) => typ === 'ktp',
          options: [
            {
              value: 'nein',
              exportValue: 0,
              label: 'Nein'
            },
            {
              value: 'ja',
              exportValue: 1,
              label: 'Ja'
            }
          ]
        },
        {
          name: 'konzeptionKvorhanden',
          label: 'Gibt es in Ihrer Kindertagespflege eine schriftliche pädagogische Konzeption bzw. ein Leitbild oder Profil?',
          formElement: 'Options',
          scalar: true,
          inline: true,
          required: ({ data: { typ } }) => typ === 'ktp',
          options: [
            {
              value: 'nein',
              exportValue: 0,
              label: 'Nein'
            },
            {
              value: 'ja',
              exportValue: 1,
              label: 'Ja'
            }
          ]
        }
      ]
    },
    {
      name: 'dokumente',
      formElement: 'Page',
      title: 'Ihre Daten',
      info: '<p>Hier können Sie die Formulardaten als PDF-Datei speichern:</p>',
      print: false,
      children: [
        {
          name: 'docs',
          formElement: 'Documents'
        }
      ]
    }
  ]
}
