import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Row, Col } from 'react-bootstrap'

import ElementHeader from '../ElementHeader'
import RequiredMarker from '../RequiredMarker'
import VariableName from '../VariableName'
import Feedback from '../Feedback'
import htmlToReact from '../../htmlToReact'
import { getData } from '../../selectors'
import styles from './Options.module.scss'

export default ({ schema, readOnly, prefix, showValidation }) => {
  const {
    name,
    label,
    options = [],
    multiple = false,
    dropdown = false,
    required
  } = schema

  const dispatch = useDispatch()
  const rawValue = useSelector(getData(name, [], prefix))
  const selection = Array.isArray(rawValue) ? rawValue : []
  const showVariableNames = useSelector(
    ({ showVariableNames }) => showVariableNames
  )

  const setData = newSelection => {
    dispatch({
      type: 'setData',
      key: name,
      value: newSelection,
      prefix
    })
  }

  const checkProps = value => ({
    id: `${name}-${value}`,
    key: value,
    type: multiple ? 'checkbox' : 'radio',
    checked: selection?.includes(value),
    onChange: e => {
      const isSelected = e.target.checked
      setData(
        multiple
          ? isSelected
            ? [...selection, value]
            : selection.filter(v => value !== v)
          : isSelected
          ? [value]
          : []
      )
    },
    className: styles.option
  })

  return (
    <div className={styles.root}>
      {dropdown ? (
        <Form.Group controlId={name} as={Row}>
          <Form.Label column sm={4}>
            {htmlToReact(label)}
            <RequiredMarker required={required} left={true} />
          </Form.Label>
          <Col sm={8}>
            <VariableName name={name} />
            <Form.Control
              as="select"
              value={selection[0] || ''}
              disabled={readOnly}
              onChange={e => {
                const value = e.target.value || ''
                setData(value === '' ? [] : [value])
              }}
            >
              <option>Bitte wählen…</option>
              {options.map(({ value, label }) => (
                <option key={value} value={value}>
                  {showVariableNames ? `[${value}] ` : null}
                  {label}
                </option>
              ))}
            </Form.Control>
            <Feedback
              name={name}
              prefix={prefix}
              showValidation={showValidation}
            />            
          </Col>
        </Form.Group>
      ) : (
        <Form.Group controlId={name}>
          <ElementHeader
            schema={schema}
            prefix={prefix}
            showValidation={showValidation}
          />
          <Form.Group>
            {options.map(({ value, label }) => (
              <>
                <VariableName value={value} />
                <Form.Check
                  label={htmlToReact(label)}
                  {...checkProps(value)}
                  custom
                  disabled={readOnly}
                />
              </>
            ))}
          </Form.Group>
        </Form.Group>
      )}
    </div>
  )
}
