import validator from 'validator'

export default (value, { min = null, max = null }) => {
  if (!validator.isInt(value)) {
    return 'dataType.integer.invalid'
  }

  const v = parseInt(value)

  if (min !== null && max !== null && (v < min || v > max)) {
    return {
      id: 'dataType.integer.minMax',
      context: {
        min,
        max
      }
    }
  }

  if (min !== null && v < min) {
    return {
      id: 'dataType.integer.min',
      context: {
        min
      }
    }
  }

  if (max !== null && v > max) {
    return {
      id: 'dataType.integer.max',
      context: {
        max
      }
    }
  }

  return null
}
