import React from 'react'
import { Table, Form } from 'react-bootstrap'

import ElementHeader from '../ElementHeader'
import VariableName from '../VariableName'
import styles from './Matrix.module.scss'

export default ({ data = {}, setData, schema, readOnly }) => {
  const { name, itemsHeader = null, options, items } = schema
  const id = name
  return (
    <div className={styles.root}>
      <ElementHeader schema={schema} />
      <div className={styles.tableWrap}>
        <Table>
          <thead>
            <tr>
              <th>{itemsHeader}</th>
              {options.map(({ value, label }) => (
                <th key={value}>
                  {label}
                  <VariableName value={value} />
                </th>
              ))}
              <th>{itemsHeader}</th>
            </tr>
          </thead>
          <tbody>
            {items.map(({ name: itemName, label }) => {
              const itemId = `${id}_${itemName}`
              return (
                <tr key={itemName}>
                  <th>
                    {label}
                    <VariableName name={`${itemId}`} />
                  </th>
                  {options.map(({ value }) => {
                    return (
                      <td key={value} className={styles.check}>
                        <Form.Check
                          id={`${itemName}-${value}`}
                          label=""
                          inline
                          custom
                          name={itemName}
                          type="radio"
                          checked={
                            data[itemName] && data[itemName].includes(value)
                          }
                          onChange={e => {
                            setData({
                              ...data,
                              [itemName]: [value]
                            })
                          }}
                          disabled={readOnly}
                        />
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}
