import React from 'react'
import { useSelector } from 'react-redux'

import t from '../getText'
import htmlToReact from '../htmlToReact'
import styles from './Feedback.module.scss'

export default ({ name, prefix, showValidation }) => {
  const {
    currentPage,
    showValidation: showPageValidation,
    messages
  } = useSelector(
    ({
      currentPage,
      showValidation,
      validation: { messages },
      modalForms
    }) => ({
      currentPage,
      showValidation,
      messages: prefix
        ? modalForms[prefix]?.validation?.messages?.[name]
        : messages[name]
    })
  )

  const show =
    typeof showValidation === 'boolean' ? showValidation : showPageValidation[currentPage]

  return show && messages?.length ? (
    <div className={styles.root}>
      <ul>
        {messages.map(({ id, context }, index) => (
          <li key={`${id}-${index}`}>{htmlToReact(t(id, context))}</li>
        ))}
      </ul>
    </div>
  ) : null
}
