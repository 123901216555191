import React from 'react'
import { useSelector } from 'react-redux'

import evaluate from '../evaluate'
import styles from './RequiredMarker.module.scss'

export default ({ required = false, left = false }) => {
  const state = useSelector(state => state)
  return evaluate(state, required) ? (
    <sup
      className={styles.root + (left ? ' ' + styles.left : '')}
      title="Pflichtfeld"
    >
      *
    </sup>
  ) : null
}
