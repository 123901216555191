import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import validator from 'validator'

import Action from '../Action'
import { register } from '../../api'
import t from '../../getText'
import cleanup from '../../cleanupStateBeforeSave'
import styles from './Common.module.scss'

const defaultState = {
  value: '',
  loading: false,
  message: null
}

export default () => {
  const dispatch = useDispatch()
  const storeState = useSelector(state => state)
  const [state, setState] = useState(defaultState)
  const { value } = state

  const handleAction = () => {
    setState({
      ...state,
      loading: true
    })
    register({ email: value, data: cleanup(storeState) })
      .then(({ data: { userName, token }, status }) => {
        if (userName && token) {
          dispatch({ type: 'login', userName, token })
          dispatch({ type: 'setAutosave', autosave: true })
          return
        }
        setState({
          ...defaultState,
          message: t('login.registeredAlready')
        })
      })
      .catch(e => {
        setState({
          ...defaultState,
          message: t('api.internalError')
        })
      })
  }

  const valid = validator.isEmail(value)

  return (
    <div className={styles.root}>
      <h3>Noch nicht registriert?</h3>
      <Form.Group controlId="email">
        <Form.Label>E-Mail-Adresse</Form.Label>
        <Form.Control
          type="email"
          value={value}
          onChange={e => setState({ ...state, value: e.target.value })}
        />
      </Form.Group>
      <Action
        state={state}
        valid={valid}
        onClick={handleAction}
        label="Registrieren"
      />
      <p>
        Bitte geben Sie Ihre E-Mail-Adresse ein und klicken Sie „Registrieren”.
        Wir senden Ihnen dann einen Zugangscode, mit dem Sie die Dateneingabe zu
        einem späteren Zeitpunkt fortsetzen können.
      </p>
    </div>
  )
}
