import React from 'react'
import { useSelector } from 'react-redux'

import FormElement from '../FormElement'

export default ({ schema: { byStatus } }) => {
  const workflowStatus = useSelector(({ workflowStatus }) => workflowStatus)

  const content = byStatus[workflowStatus]

  return <>{content ? <FormElement schema={content} /> : null}</>
}
