import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default ({ title, show, setShow, save, cancel, children }) => (
  <Modal
    show={show}
    onHide={() => setShow(false)}
    aria-labelledby="contained-modal-title-vcenter"
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        onClick={() => {
          if (typeof cancel === 'function') {
            cancel()
          } else {
            setShow(false)
          }
        }}
      >
        Abbrechen
      </Button>
      <Button variant="success" onClick={() => save()}>
        Übernehmen
      </Button>
    </Modal.Footer>
  </Modal>
)
