import React from 'react'
import { useSelector } from 'react-redux'
import { ProgressBar } from 'react-bootstrap'

export default () => {
  const progress = useSelector(({ validation: { progress } }) => progress)

  return (
    <ProgressBar
      now={progress}
      label={`${progress}%`}
      variant="ok"
      style={{ filter: `saturate(${progress}%)` }}
    />
  )
}
