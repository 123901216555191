import React from 'react'

import RequiredMarker from './RequiredMarker'
import Info from './FormElements/Info'
import VariableName from './VariableName'
import Feedback from './Feedback'
import htmlToReact from '../htmlToReact'
import styles from './ElementHeader.module.scss'

export default ({
  schema: { name, label, title, info, required },
  showValidation,
  prefix
}) => (
  <div className={styles.root}>
    <h5>
      {title ? title : htmlToReact(label)}
      <RequiredMarker required={required} left={true} />
    </h5>
    <VariableName name={name} />
    <Info info={info} />
    <div className={styles.feedback}>
      <Feedback name={name} showValidation={showValidation} prefix={prefix} />
    </div>
  </div>
)
