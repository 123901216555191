import crawl from 'tree-crawl'

import * as validators from './validators'
import evaluate from './evaluate'

const validateElements = [
  'Form',
  'Page',
  'Section',
  'Textinput',
  'Options',
  'Matrix',
  'List',
  'Personal'
]

const counterDefaults = {
  countRequired: 0,
  countRequiredValid: 0,
  countErrors: 0
}

const messagePrefix = path => 'validation.' + path

const getMessages = result => {
  if (typeof result === 'string') {
    return [{ id: messagePrefix(result) }]
  }
  if (Array.isArray(result)) {
    return result.map(r =>
      typeof r === 'string'
        ? { id: messagePrefix(r) }
        : { id: messagePrefix(r.id) }
    )
  }
  return [{ id: messagePrefix(result.id), context: result.context }]
}

export default (state, schema, detailState = null) => {
  let countRequired = 0
  let countRequiredValid = 0
  let countErrors = 0
  let perPage = {
    null: { ...counterDefaults }
  }
  let messages = {}
  let page = null

  const handleNode = (node, context) => {
    if (!validateElements.includes(node.formElement)) {
      context.skip()
    }
    const { name, formElement, required } = node

    if (formElement === 'Page') {
      page = name
      perPage[page] = { ...counterDefaults }
    } else {
      const isRequired = evaluate(state, required)
      if (isRequired) {
        countRequired++
        perPage[page].countRequired++
      }

      const validator = validators[formElement]
      if (validator && !(formElement === 'List' && detailState)) {
        const result = validator(
          detailState ? detailState : state,
          node,
          isRequired
        )
        if (result) {
          countErrors++
          perPage[page].countErrors++
          messages[name] = getMessages(result)
        } else if (isRequired) {
          perPage[page].countRequiredValid++
          countRequiredValid++
        }
      }
    }
  }

  crawl(schema, handleNode, {
    getChildren: node =>
      !detailState && node.formElement === 'List' ? [] : node.children
  })

  const progress =
    countRequired > 0
      ? Math.round((100 * countRequiredValid) / countRequired)
      : 0

  const isFinalizable =
    state.workflowStatus === 'eingabeEinrichtung' &&
    progress >= 100 &&
    !Object.keys(messages).length

  return {
    countErrors,
    countRequired,
    countRequiredValid,
    progress,
    isFinalizable,
    perPage,
    messages
  }
}
