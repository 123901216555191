import React from 'react'
import { Button, Table } from 'react-bootstrap'

import styles from './DataTable.module.scss'

const renderColumnDefault = (row, index, key) => row[key]

export default ({
  data = [],
  columns = [],
  editRow = () => null,
  deleteRow = () => null,
  renderColumn = renderColumnDefault,
  readOnly
}) =>
  data.length ? (
    <div className={styles.root}>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            {Object.entries(columns).map(([key, label]) => (
              <th key={key}>{label}</th>
            ))}
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            const { id } = row
            return (
              <tr key={id}>
                {Object.keys(columns).map(key => (
                  <td key={key}>{renderColumn(row, index, key)}</td>
                ))}
                <td className={styles.actionColumn}>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => editRow(id)}
                    disabled={readOnly}
                  >
                    Bearbeiten
                  </Button>{' '}
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => deleteRow(id)}
                    disabled={readOnly}
                  >
                    Löschen
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  ) : null
