import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import Info from './Info'
import Action from '../Action'
import t from '../../getText'
import { downloadPdf } from '../../api'

const defaultState = {
  loading: false,
  message: null
}

export default () => {
  const [state, setState] = useState(defaultState)
  const { valid, token } = useSelector(
    ({ validation: { countErrors }, token }) => ({
      token,
      valid: countErrors === 0
    })
  )

  const handleClick = e => {
    downloadPdf(token, status =>
      setState({
        loading: status === 'loading',
        message: status === 'fail' ? t('api.internalError') : null
      })
    )
  }

  // disabled={!valid}

  return (
    <>
      {!valid && (
        <Info info="Bitte füllen Sie das Formular zuerst vollständig aus." />
      )}
      <Action
        valid={token && valid}
        onClick={handleClick}
        label="PDF-Datei herunterladen"
        variant="primary"
        state={state}
      />
    </>
  )
}
