import React from 'react'

import FormElement from '../FormElement'
import Hint from '../Hint'
import Info from './Info'
import styles from './Section.module.scss'

export default ({
  schema: { name, title = null, hint = null, info = null, children = [] },
  ...rest
}) => (
  <div className={styles.root}>
    {title && <h5>{title}</h5>}
    <Hint>{hint}</Hint>
    <Info info={info} />
    <FormElement {...rest} schema={children} />
  </div>
)
