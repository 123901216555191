import postprocess from './postprocess'
import * as actions from './actions'
import getDefaultState from './getDefaultState'

const reducer = (state, action) =>
  actions[action.type] ? actions[action.type](state, action) : state

// Chain reducer => validation
export default (state = getDefaultState(), action) =>
  postprocess(reducer(state, action))
