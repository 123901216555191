import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

import { finalize } from '../api'

import Confirm from './Confirm'
import styles from './Finalize.module.scss'

export default () => {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const { isLoggedIn, isFinalizable, token } = useSelector(
    ({ isLoggedIn, validation: { isFinalizable }, token }) => ({
      isLoggedIn,
      isFinalizable,
      token
    })
  )

  const onConfirmed = () => {
    setShow(false)
    // this won't be applied
    dispatch({
      type: 'setCurrentPage',
      currentPage: 'start'
    })
    finalize(token).then(({ data }) => {
      if (data === 'ok') {
        dispatch({
          type: 'setWorkflowStatus',
          workflowStatus: 'eingabeEinrichtungAbgeschlossen'
        })
      }
    })
  }

  return isLoggedIn && isFinalizable ? (
    <>
      <Confirm
        title="Bearbeitung abschließen"
        caption="Bearbeitung abschließen"
        show={show}
        setShow={setShow}
        variant="success"
        onConfirmed={onConfirmed}
      >
        <p>
          Bitte bestätigen Sie uns hiermit, dass die Informationen vollständig
          und korrekt sind. Wir melden uns dann zeitnah, um Ihre individuellen
          Wünsche zu besprechen und die Evaluation gemeinsam zu planen.
        </p>
        <p>Herzlichen Dank für Ihre Mühe bei der Datenerfassung!</p>
        <p>
          <strong>
            Bitte beachten Sie, dass die Daten nach Abschluss vorerst nicht mehr
            bearbeitet werden können.
          </strong>
        </p>
      </Confirm>
      <div
        className={classNames(styles.root, {
          [styles.active]: isFinalizable
        })}
      >
        <p>Alle Daten vollständig eingegeben?</p>
        <Button
          disabled={!isFinalizable}
          variant="success"
          size="sm"
          onClick={() => setShow(true)}
        >
          Bearbeitung abschließen
        </Button>
      </div>
    </>
  ) : null
}
