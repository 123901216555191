import React from 'react'

import Dynamic from '../Dynamic'
import htmlToReact from '../../htmlToReact'
import styles from './Info.module.scss'

export default ({ info, schema, children }) => {
  const html = info ? info : schema?.info
  return typeof info === 'function' ? (
    <div className={styles.root}>
      <Dynamic>{info}</Dynamic>
    </div>
  ) : children ? (
    <div className={styles.root}>{children}</div>
  ) : html ? (
    <div className={styles.root}>{htmlToReact(html)}</div>
  ) : null
}
