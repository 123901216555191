import validate from './validate'
import validateModalForms from './validateModalForms'
import variables from './variables'
import { getList } from './selectors'
import evaluate from './evaluate'

const getPageNames = (schema, state) =>
  schema
    ? schema.children
        .filter(
          ({ formElement, visible }) =>
            formElement === 'Page' && evaluate(state, visible, true)
        )
        .map(({ name }) => name)
    : []

export default state => {
  const { schema } = state || {}
  const pageNames = getPageNames(schema, state)
  const activePages = state.isLoggedIn
    ? pageNames
    : state.acceptTerms
    ? ['start', 'anmeldung']
    : ['start']

  const currentPage = activePages.includes(state.currentPage)
    ? state.currentPage
    : activePages[0]

  // @todo check accessibility

  const currentIndex = activePages.indexOf(currentPage)
  const prevIndex = currentIndex > 0 ? currentIndex - 1 : null
  const nextIndex =
    currentIndex + 1 < activePages.length ? currentIndex + 1 : null

  // cleanup relations
  const gruppenIds = getList('gruppen')(state).map(({ id }) => id)
  const fachkraefteIds = getList('fachkraefte')(state).map(({ id }) => id)
  const assignments = state.data?.personal || []
  const personal = assignments.filter(
    ([personId, groupId]) =>
      fachkraefteIds.includes(personId) && gruppenIds.includes(groupId)
  )

  const readOnly = state.workflowStatus === 'eingabeEinrichtung' ? false : true

  const modalForms = validateModalForms(state, schema)

  return {
    ...state,
    data: {
      ...state.data,
      personal,
      ...variables(state, schema)
    },
    prevPage: prevIndex !== null ? activePages[prevIndex] : null,
    currentPage,
    nextPage: nextIndex !== null ? activePages[nextIndex] : null,
    activePages,
    validation: validate(state, schema),
    visibility: {},
    readOnly,
    modalForms
  }
}
