import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'

export default () => {
  const dispatch = useDispatch()
  const { isLoggedIn, userName } = useSelector(({ isLoggedIn, userName }) => ({
    isLoggedIn,
    userName
  }))

  return isLoggedIn ? (
    <div>
      <h3>
        Sie sind angemeldet als <strong>{userName}</strong>.
      </h3>
      <Button variant="danger" onClick={() => dispatch({ type: 'logout' })}>
        Abmelden
      </Button>
    </div>
  ) : null
}
