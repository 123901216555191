import React from 'react'
import { useSelector } from 'react-redux'

import htmlToReact from '../../htmlToReact'

export default ({ schema: { html } }) => {
  const data = useSelector(({ data }) => data)
  const content = typeof html === 'function' ? html(data) : html

  return <div>{htmlToReact(content)}</div>
}
