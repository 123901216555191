import React from 'react'

import Dynamic from './Dynamic'
import RequiredMarker from './RequiredMarker'

export default ({ label, required }) => (
  <>
    <Dynamic>{label}</Dynamic>
    <RequiredMarker required={required} />
  </>
)
