import React from 'react'
import { Button } from 'react-bootstrap'

import Message from './Message'
import Spinner from './Spinner'

export default ({
  state: { message, loading },
  valid,
  onClick,
  label,
  variant = 'success'
}) => (
  <>
    <Button disabled={!valid || loading} variant={variant} onClick={onClick}>
      {label}
    </Button>
    <Message message={message} />
    <Spinner active={loading} />
  </>
)
