import { saveData } from '../api'
import cleanup from '../cleanupStateBeforeSave'

const delayedSaveMilliseconds = 1000
let saveTimeoutID = null

export const delayedSaveData = data => {
  if (saveTimeoutID) {
    window.clearTimeout(saveTimeoutID)
    saveTimeoutID = null
  }
  saveTimeoutID = window.setTimeout(() => {
    saveData(data, result => {
      // console.log(result)
    })
    saveTimeoutID = null
  }, delayedSaveMilliseconds)
}

export default store => next => action => {
  const returnValue = next(action)
  if (action.type !== 'loadData') {
    const { schema, ...data } = store.getState()
    if (
      data.autosave &&
      data.isLoggedIn &&
      data.token &&
      data.acceptTerms &&
      data.workflowStatus === 'eingabeEinrichtung'
    ) {
      const cleanData = cleanup(data)
      delayedSaveData(cleanData)
    }
  }
  return returnValue
}
