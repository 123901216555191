import { createElement } from 'react'

import evaluate from '../evaluate'
import htmlToReact from '../htmlToReact'

export default ({ children, as, state }) => {
  if (typeof children === 'undefined') {
    return null
  }
  const value = evaluate(state, children, null)
  const contents = htmlToReact(value)
  return value !== null
    ? as
      ? createElement(as, {}, contents)
      : contents
    : null
}
