import React from 'react'

import styles from './ElementInfo.module.scss'

export default ({ schema, children }) => (
  <div className={styles.root}>
    <div className={styles.formElement}>{schema.formElement || 'Textinput'}</div>
    {children}
  </div>
)
