import React from 'react'
import classNames from 'classnames'

import logo from './logo.svg'
import styles from './Header.module.scss'

export default ({ title, sidebarOpen }) => (
  <div
    className={classNames(styles.root, {
      [styles.sidebarOpen]: sidebarOpen
    })}
  >
    {title && <h2>{title}</h2>}
    <h1>
      <a href="https://paedquis.de/" title="Zur Website der pädquis Stiftung">
        <img src={logo} alt="pädquis Stiftung" />
      </a>
    </h1>
  </div>
)
