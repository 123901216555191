import * as typeValidators from '../typeValidators'

export default (state, schema, required) => {
  const {
    name,
    dataType,
    multiline,
    minLength,
    maxLength: customMaxLength
  } = schema
  const value = state.data[name]
  const trimmedValue = value ? value.trim() : null
  const isEmpty = !(trimmedValue?.length > 0)

  if (minLength && (!trimmedValue || trimmedValue?.length < minLength)) {
    return {
      id: 'minLength',
      context: {
        minLength
      }
    }
  } else if (required && isEmpty) {
    return 'required.required'
  }

  const maxLength = customMaxLength ? customMaxLength : multiline ? 10000 : 100
  if (trimmedValue?.length > maxLength) {
    return {
      id: 'maxLength',
      context: {
        maxLength
      }
    }
  }

  if (!isEmpty && dataType && typeValidators[dataType]) {
    return typeValidators[dataType](value, schema)
  }

  return null
}
