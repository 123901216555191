import { useSelector, useDispatch } from 'react-redux'

import { getData } from './selectors'

export default (key, defaultValue, prefix) => {
  const dispatch = useDispatch()
  const value = useSelector(getData(key, defaultValue, prefix))
  const onChange = e => {
    const { type, value: v, checked } = e.target
    const value = type === 'checkbox' ? checked === 'on' : v
    dispatch({ type: 'setData', key, value, prefix })
  }

  const onBlur = e => {
    const { type, value } = e.target
    if (['text', 'textarea'].includes(type)) {
      const trimmed = value.trim()
      if (trimmed !== value) {
        dispatch({ type: 'setData', key, value: trimmed })
      }
    }
  }

  return {
    value,
    onChange,
    onBlur
  }
}
