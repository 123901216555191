import React from 'react'

import styles from './Contact.module.scss'

export default () => (
  <div className={styles.root}>
    <p>
      Haben Sie Fragen zum Formular oder zur Evaluation? Rufen Sie uns gerne an
      oder schreiben Sie uns:
    </p>
    <p className={styles.impact}>
      +49 30 720061-17
      <br />
      <a href="mailto:evaluation@paedquis.de?subject=registrierung.paedquis.de">
        evaluation@paedquis.de
      </a>
      <br />
    </p>
    <p>Montag bis Freitag 9:00–16:00 Uhr</p>
  </div>
)
