export default (state, schema, required) => {
  const { name } = schema
  const value = state.data[name]

  return required && (!value || !value.length)
    ? {
        id: schema.multiple
          ? 'required.options.multiple'
          : 'required.options.single'
      }
    : null
}
