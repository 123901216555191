import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Table, Form, Button } from 'react-bootstrap'
import styles from './PersonsGroupsMatrix.module.scss'

const relationName = 'fachkraefte-gruppen'

export default ({
  persons = [],
  groups = [],
  data = [],
  setData = () => null,
  deletePerson = () => null,
  readOnly
}) => {
  const dispatch = useDispatch()
  const relations = useSelector(
    ({ data: { _relations = {} } }) => _relations[relationName] || []
  )

  const isAssigned = (personId, groupId) =>
    relations.find(([p, g]) => p === personId && g === groupId)

  const createAssignment = (personId, groupId) =>
    dispatch({
      type: 'addRelation',
      name: relationName,
      keys: [personId, groupId]
    })

  const deleteAssignment = (personId, groupId) =>
    dispatch({
      type: 'removeRelation',
      name: relationName,
      keys: [personId, groupId]
    })

  return persons.length ? (
    <div className={styles.root}>
      <Table>
        <thead>
          <tr>
            <th>Fachkraft</th>
            {groups.map(({ id, name }) => (
              <th key={id}>
                <span className={styles.rotatedHeader}>{name}</span>
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {persons.map(({ id: personId, anrede, vorname, nachname }) => {
            const anredeLabel =
              anrede === 'frau' ? 'Frau' : anrede === 'herr' ? 'Herr' : ''
            const fullName = `${anredeLabel} ${vorname} ${nachname}`.trim()

            return (
              <tr key={personId}>
                <th>{fullName}</th>
                {groups.map(({ id: groupId, name: groupName }) => {
                  const assigned = isAssigned(personId, groupId)
                  const info = assigned
                    ? `${fullName} ist in ${groupName} tätig`
                    : `${fullName} ist nicht in ${groupName} tätig`
                  return (
                    <td key={groupId}>
                      <Form.Check
                        id={`${personId}-${groupId}`}
                        type="checkbox"
                        title={info}
                        custom
                        label=""
                        aria-label={info}
                        checked={isAssigned(personId, groupId)}
                        onChange={e => {
                          e.target.checked
                            ? createAssignment(personId, groupId)
                            : deleteAssignment(personId, groupId)
                        }}
                        disabled={readOnly}
                      />
                    </td>
                  )
                })}
                <td>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={e => deletePerson(personId)}
                    disabled={readOnly}
                  >
                    Löschen
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  ) : null
}
