import axios from 'axios'

export default (url, setStatus = () => null) => {
  setStatus('loading')

  axios({
    url,
    method: 'GET',
    responseType: 'blob'
  })
    .then(response => {
      if (response.status === 200) {
        setStatus('success')
        const filename = /filename=(.*)$/.exec(
          response.headers['content-disposition']
        )[1]
        if (!window.navigator.msSaveOrOpenBlob) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        } else {
          // BLOB FOR EXPLORER 11
          window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename)
        }
      } else {
        setStatus('fail')
      }
    })
    .catch(e => {
      setStatus('fail')
    })
}
