import crawl from 'tree-crawl'

export default (state, schema) => {
  let variables = {}
  crawl(
    schema,
    (node, context) => {
      if (node.formElement === 'Variable') {
        const { name, expression } = node
        const value =
          typeof expression === 'function' ? expression(state.data, state) : null
        if (value !== null) {
          variables[name] = value
        }
      }
    },
    { getChildren: node => node.children }
  )
  return variables
}
