import evaluate from '../evaluate'

export default (state, schema) => {
  const { data } = state
  const { name, minEntries = null, required } = schema
  const isRequired = evaluate(state, required, true)
  if (!isRequired) {
    return null
  }

  const list = data._lists?.[name] || []
  const n = list.length
  if (minEntries && minEntries > n) {
    return 'minEntries'
  }
  return null
}
