import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Cleave from 'cleave.js/react'
import { Form } from 'react-bootstrap'

import Action from '../Action'
import { loadByToken } from '../../api'
import t from '../../getText'
import styles from './Common.module.scss'

const defaultState = {
  value: '',
  loading: false,
  message: null
}

export default () => {
  const dispatch = useDispatch()
  const [state, setState] = useState(defaultState)
  const { value } = state

  const handleAction = () => {
    setState({
      ...state,
      loading: true
    })
    loadByToken(value)
      .then(({ data, status }) => {
        if (status === 200) {
          if (data) {
            const { userName, token } = data
            dispatch({ type: 'login', userName, token })
            dispatch({ type: 'loadData', data })
          } else {
            setState({
              ...state,
              loading: false,
              message: t('login.tokenInvalid')
            })
          }
        } else {
          setState({
            ...defaultState,
            message: t('api.internalError')
          })
        }
      })
      .catch(e => {
        setState({
          ...defaultState,
          message: t('api.internalError')
        })
      })
  }

  const valid = value.trim().length === 9

  return (
    <div className={styles.root}>
      <h3>Haben Sie bereits Daten eingegeben?</h3>
      <Form.Group controlId="token">
        <Form.Label>Zugangscode</Form.Label>
        <Cleave
          id="token"
          options={{
            blocks: [3, 3, 3],
            delimiter: '-',
            uppercase: true
          }}
          value={value}
          className={`${styles.tokenInput} form-control`}
          onChange={e =>
            setState({
              ...state,
              value: e.target.rawValue
            })
          }
        />
      </Form.Group>
      <Action
        state={state}
        valid={valid}
        onClick={handleAction}
        label="Senden"
      />
      <p>
        Durch Eingabe des Zugangscodes, den wir Ihnen per E-Mail gesendet haben
        und Klick auf „Senden“ können Sie Ihre Daten einsehen und die Eingabe
        fortsetzen.
      </p>
    </div>
  )
}
