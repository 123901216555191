import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import classNames from 'classnames'

import connectInput from '../../connectInput'
import VariableName from '../VariableName'
import Label from '../Label'
import Hint from '../Hint'
import Feedback from '../Feedback'

import styles from './Textinput.module.scss'

export default ({ schema, readOnly, prefix, showValidation }) => {
  const {
    name,
    label,
    postfix = null,
    hint = null,
    labelOnTop = false,
    dataType = null,
    multiline = false,
    required = false,
    className: customClassName = null
  } = schema

  const type =
    dataType === 'number' || dataType === 'integer' || dataType === 'decimal' ? 'number' : 'text'

  const className = classNames(
    styles.root,
    {
      [styles.labelOnTop]: labelOnTop,
      [styles.number]: type === 'number'
    },
    customClassName
  )

  const [w1, w2] = type === 'number' ? [8, 4] : [4, 8]

  return multiline ? (
    <Form.Group controlId={name} className={className}>
      <Form.Label column sm={w1} required={required}>
        <Label label={label} required={required} />
      </Form.Label>
      <Hint>{hint}</Hint>
      <VariableName name={name} />
      <TextareaAutosize
        id={name}
        className="form-control"
        minRows={3}
        maxRows={20}
        {...connectInput(name, '', prefix)}
        disabled={readOnly}
      />
      <Feedback name={name} prefix={prefix} showValidation={showValidation} />
    </Form.Group>
  ) : (
    <Form.Group as={Row} controlId={name} className={className}>
      <Form.Label column sm={w1} required={required}>
        <Label label={label} required={required} />
      </Form.Label>
      <Hint>{hint}</Hint>
      <Col sm={w2}>
        <VariableName name={name} />
        <div className={styles.inputWrap}>
        <Form.Control
          type={type}
          required={required}
          {...connectInput(name, '', prefix)}
          disabled={readOnly}
        />
        {postfix && (
                <span className={styles.postfix}>
                  {postfix}
                </span>
              )}
        </div>
        <Feedback name={name} prefix={prefix} showValidation={showValidation} />
      </Col>
    </Form.Group>
  )
}
