import React from 'react'
import { useSelector } from 'react-redux'

import styles from './VariableName.module.scss'

export default ({ name, value }) => {
  const showVariableNames = useSelector(
    ({ showVariableNames }) => showVariableNames
  )
  return showVariableNames ? (
    <span className={styles.root}>
      {name && <span className={styles.name}>{name}</span>}
      {value && <span className={styles.value}>{value}</span>}
    </span>
  ) : null
}
