import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'

import htmlToReact from '../../htmlToReact'
import Confirm from '../Confirm'

import styles from './Privacy.module.scss'

export default ({ schema: { html, url }, readOnly }) => {
  const dispatch = useDispatch()
  const acceptTerms = useSelector(
    ({ acceptTerms= false }) => acceptTerms
  )
  const [show, setShow] = useState(false)

  return (
    <div className={styles.root}>
      <Confirm
        title="Widerruf Datenverarbeitung"
        caption="Daten löschen"
        show={show}
        setShow={setShow}
        onConfirmed={e => {
          setShow(false)
          dispatch({ type: 'reset' })
        }}
      >
        <p>
          Bei Widerruf der Zustimmung zur Speicherung und zur Verarbeitung
          werden sämtliche Formulareingaben unwiederbringlich gelöscht.
        </p>
        <p>
          <strong>
            Sind Sie sicher, dass sämtliche Inhalte gelöscht werden sollen?
          </strong>
        </p>
      </Confirm>
      <Form.Group>
        <Form.Check
          id="privacy"
          type="checkbox"
          custom
          checked={acceptTerms}
          label="Ich stimme der Speicherung und der Verarbeitung gemäß Datenschutzerklärung zu."
          onChange={e => {
            if (e.target.checked) {
              dispatch({ type: 'acceptTerms' })
            } else {
              e.preventDefault()
              setShow(true)
            }
          }}
          disabled={readOnly}
        />
      </Form.Group>
      <div className={styles.terms}>{htmlToReact(html)}</div>
      <p>
        <a target="_" href={url} onClick={e => e.preventDefault()}>
          Datenschutzerklärung herunterladen
        </a>
      </p>
    </div>
  )
}
