import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Provider } from 'react-redux'

import store from '../store'
import AutoForm from './AutoForm'
import schema from '../forms/strukturfragebogen'

import './App.css'

export default () => (
  <Provider store={store}>
    <AutoForm schema={schema} />
  </Provider>
)
