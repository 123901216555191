import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Row, Col } from 'react-bootstrap'
import useEventListener from '@use-it/event-listener'

import ProgressBar from './ProgressBar'
import t from '../getText'
import styles from './Navigation.module.scss'

const isInputTarget = e =>
  !['INPUT', 'TEXTAREA'].includes(e.target.tagName) || e.target?.value === ''

export default () => {
  const dispatch = useDispatch()
  const {
    currentPage,
    prevPage,
    nextPage,
    isLoggedIn,
    showValidation,
    perPage,
    readOnly
  } = useSelector(
    ({
      currentPage,
      prevPage,
      nextPage,
      isLoggedIn,
      showValidation,
      validation: { perPage },
      readOnly
    }) => ({
      currentPage,
      prevPage,
      nextPage,
      isLoggedIn,
      showValidation,
      perPage,
      readOnly
    })
  )

  const goto = pageName => {
    if (!showValidation[currentPage]) {
      dispatch({ type: 'showValidation', pageName: currentPage })
      if (perPage[currentPage]?.countErrors) {
        return
      }
    }
    dispatch({ type: 'setCurrentPage', currentPage: pageName })
  }

  useEventListener('keydown', e => {
    if (!isInputTarget(e)) {
      return
    }
    if (prevPage && e.which === 37) {
      e.preventDefault()
      goto(prevPage)
    } else if (nextPage && e.which === 39) {
      e.preventDefault()
      goto(nextPage)
    }
  })

  return (
    <div className={styles.root}>
      <Row>
        <Col>
          {prevPage && (
            <Button
              onClick={() => goto(prevPage)}
              className={styles.navigation}
            >
              {t('navigation.prev')}
            </Button>
          )}
        </Col>
        <Col className={styles.center}>
          {isLoggedIn && !readOnly && <ProgressBar />}
        </Col>
        <Col style={{ textAlign: 'right' }}>
          {nextPage && (
            <Button
              type="submit"
              onClick={() => goto(nextPage)}
              className={styles.navigation}
            >
              {t('navigation.next')}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  )
}
