import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import classNames from 'classnames'

import Dynamic from './Dynamic'
import evaluate from '../evaluate'
import styles from './Menu.module.scss'

const StatusIcon = ({ name, show, errors, onClick }) => {
  if (!show) {
    return null
  }

  const status = errors ? 'error' : 'ok'
  const statusInfo = errors
    ? `${errors} Fehler gefunden`
    : 'Eingaben fehlerfrei'
  const className = classNames(styles.statusIcon, {
    [styles[status]]: true
  })

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-top`}>{statusInfo}</Tooltip>}
      onClick={onClick}
    >
      <span className={className}></span>
    </OverlayTrigger>
  )
}

export default ({ title }) => {
  const state = useSelector(state => state)
  const {
    schema,
    currentPage,
    activePages,
    showValidation,
    perPage
  } = useSelector(
    ({
      schema,
      currentPage,
      activePages,
      showValidation,
      validation: { perPage }
    }) => ({
      schema,
      currentPage,
      activePages,
      showValidation,
      perPage
    })
  )
  const dispatch = useDispatch()

  return schema ? (
    <div className={styles.root}>
      <nav aria-labelledby="menu">
        <h3 id="menu">Menü</h3>
        <ul>
          {schema.children.map(({ name, title, titleShort, visible }) => {
            const isCurrentPage = name === currentPage
            const className = classNames({ [styles.active]: isCurrentPage })

            const onClick = e => {
              e.preventDefault()
              dispatch({ type: 'setCurrentPage', currentPage: name })
            }

            const caption =
              titleShort || title ? (
                <Dynamic>{titleShort ? titleShort : title}</Dynamic>
              ) : null

            const isVisible = evaluate(state, visible, true)

            return isVisible && (
              <li key={name} className={className}>
                {activePages.includes(name) ? (
                  <a
                    aria-current={isCurrentPage ? 'page' : null}
                    href={`#${name}`}
                    onClick={onClick}
                  >
                    {caption}
                  </a>
                ) : (
                  <span className={styles.currentPage}>{caption}</span>
                )}
                <StatusIcon
                  name={name}
                  show={showValidation[name]}
                  errors={perPage[name]?.countErrors}
                  onClick={onClick}
                />
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  ) : null
}
