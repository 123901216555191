export default () => {
  const { href } = window.location
  const url = new URL(href)
  const search = Object.fromEntries(new URLSearchParams(url.search))

  const { protocol, hash, host, hostname, port } = url

  return {
    protocol,
    hash,
    host,
    hostname,
    port,
    href,
    search
  }
}
