import arrayDiffer from 'arr-diff'

import evaluate from '../evaluate'

// Count object ids NOT referenced in relations
const countMissing = (list1, list2, relations = []) => {
  // Get unique list of all object ids
  const objectIds = [...new Set([...list1, ...list2].map(({ id }) => id))]
  // Get unique list of all object ids references
  const relationIds = [
    ...new Set(relations.reduce((acc, r) => [...acc, ...r], [])),
  ]
  return arrayDiffer(objectIds, relationIds).length
}

export default (state, schema, required) => {
  const { data: { _lists, _relations } } = state
  const { name, listNames, relationName, withMatrix } = schema

  if (!(_lists?.[name] || []).length) {
    return 'personal.required'
  }

  const showMatrix = evaluate(state, withMatrix, true)
  if (!showMatrix) {
    return null
  }

  return countMissing(
    _lists[listNames[0]] || [],
    _lists[listNames[1]] || [],
    _relations?.[relationName] || []
  )
    ? 'personal.assignments'
    : null
}
