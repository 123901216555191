import crawl from 'tree-crawl'

import validate from './validate'

const getSchemaByName = (schema, name) => {
  let found = null
  crawl(
    schema,
    (node, context) => {
      if (node?.name === name) {
        found = node
        context.break()
      }
    },
    { getChildren: node => node.children }
  )
  return found
}

export default (state, schema) =>
  Object.entries(state.modalForms).reduce((acc, [name, { data }]) => {
    const schemaDetail = getSchemaByName(schema, name)
    return schemaDetail
      ? {
          ...acc,
          [name]: {
            ...state.modalForms[name],
            validation: validate(state, schemaDetail, { data })
          }
        }
      : {
          ...acc,
          [name]: state.modalForms[name]
        }
  }, {})
