import React from 'react'

export default ({ schema }) => {
  return (
    <>
      <h3>Unknown form element: {schema.type}</h3>
      <pre>{JSON.stringify(schema, null, 2)}</pre>
    </>
  )
}
