import { createStore, applyMiddleware, compose } from 'redux'

import reducer from './reducer'
import storageMiddleware from './middleware/storage'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  /* preloadedState, */ composeEnhancers(
    applyMiddleware(storageMiddleware)
  )
)

export default store
