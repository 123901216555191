const omitFields = [
  'showVariableNames',
  'isLoggedIn',
  'activePages',
  'messages',
  'modalForms'
]

export default state =>
  Object.keys(state)
    .filter(k => !omitFields.includes(k))
    .reduce(
      (acc, k) => ({
        ...acc,
        [k]: state[k]
      }),
      {}
    )
