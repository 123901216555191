import React, { useState, useRef, useEffect } from 'react'

import { Form, Row, Col, Button } from 'react-bootstrap'

import styles from './PersonForm.module.scss'

const defaultData = { anrede: '', vorname: '', nachname: '' }

const trimData = data =>
  Object.keys(data).reduce(
    (acc, key) => ({
      ...acc,
      [key]: data[key].trim()
    }),
    {}
  )

export default ({ persons = [], addPerson = () => null, readOnly }) => {
  const refFirstname = useRef()
  const refLastname = useRef()
  const [data, setData] = useState(defaultData)
  const { vorname, nachname } = data
  const trimmedData = trimData(data)

  const exists = persons.find(
    p =>
      p.vorname === trimmedData.vorname &&
      p.nachname === trimmedData.nachname
  )

  const addDisabled =
    exists ||
    trimmedData.vorname.length < 1 ||
    trimmedData.nachname.length < 1

  const add = () => {
    if (addDisabled) {
      return
    }
    addPerson(trimmedData)
    setData(defaultData)
    refFirstname.current.focus()
  }

  const submitOnEnter = e => e.key === 'Enter' && add()
  const nextOnEnter = e => e.key === 'Enter' && refLastname.current.focus()

  useEffect(() => refFirstname.current.focus(), [])

  return (
    <div className={styles.root}>
      <Row>
        <Col className={`col-auto ${styles.col}`}>
          <Form.Control
            as="select"
            disabled={readOnly}
            onChange={e => setData({ ...data, anrede: e.target.value })}
          >
            <option>Bitte wählen…</option>
            <option value="frau">Frau</option>
            <option value="herr">Herr</option>
          </Form.Control>
        </Col>
        <Col className={`col-auto ${styles.col}`}>
          <Form.Control
            ref={refFirstname}
            placeholder="Vorname"
            value={vorname}
            onChange={e => setData({ ...data, vorname: e.target.value })}
            onKeyPress={nextOnEnter}
            disabled={readOnly}
          />
        </Col>
        <Col className={`col-auto ${styles.col}`}>
          <Form.Control
            ref={refLastname}
            placeholder="Nachname"
            value={nachname}
            onChange={e => setData({ ...data, nachname: e.target.value })}
            onKeyPress={submitOnEnter}
            disabled={readOnly}
          />
        </Col>
        <Col className={`col-auto ${styles.col}`}>
          <Button disabled={readOnly || addDisabled} variant="success" onClick={add}>
            Hinzufügen
          </Button>
        </Col>
      </Row>
      {exists && (
        <div className="invalid-feedback">Eintrag ist bereits vorhanden.</div>
      )}
    </div>
  )
}
