import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'

import { getList } from '../../selectors'
import generateUuid from '../../uuid'
import evaluate from '../../evaluate'

import Info from './Info'
import FormElement from '../FormElement'
import ModalForm from '../ModalForm'
import DataTable from '../DataTable'
import Confirm from '../Confirm'

const captionsDefaults = {
  add: 'Eintrag hinzufügen',
  edit: 'Eintrag bearbeiten',
  create: 'Neuen Eintrag anlegen',
  deleteTitle: 'Eintrag löschen',
  deleteConfirm: () => `Sind Sie sicher, dass der Eintrag gelöscht werden soll?`
}

const getByKey = (arr = [], value, keyName = 'id') =>
  arr.find(r => r[keyName] === value)

const getLabeledOptions = (options, schema) => {
  if (typeof options === 'string') {
    const o = getByKey(schema, options, 'value')
    return o?.label ? o.label : `${options}`
  } else {
    return options?.length
      ? options
          .map(value => {
            const o = getByKey(schema, value, 'value')
            return o?.label ? o.label : `${value}`
          })
          .join(', ')
      : ''
  }
}

const renderColumn = ({ children }) => {
  return (row, index, key) => {
    if (key === 'index') {
      return index + 1
    }
    const value = row[key] || null
    const schema = getByKey(children, key, 'name')
    if (schema?.formElement === 'Options') {
      return getLabeledOptions(value, schema.options)
    }
    return value
  }
}

export default ({ schema, readOnly, ...rest }) => {
  const {
    name: listName,
    info,
    children,
    captions: captionsCustom,
    columns = [],
    maxEntries
  } = schema

  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const data = useSelector(getList(listName))
  const mf = useSelector(state => state.modalForms?.[listName] || {})

  const [deleteId, setDeleteId] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showValidation, setShowValidation] = useState(false)

  const editEntry = id => {
    dispatch({
      type: 'addModalForm',
      name: listName,
      data: id ? getByKey(data, id) || {} : {}
    })
    setShowForm(true)
  }

  const cleanup = () => {
    setShowValidation(false)
    setShowForm(false)
    dispatch({
      type: 'removeModalForm',
      name: listName
    })
  }

  const upsert = data => dispatch({ type: 'upsertListEntry', listName, data })
  const remove = id => dispatch({ type: 'removeListEntry', listName, id })
  const deleteObject = getByKey(data, deleteId)

  const captions = {
    ...captionsDefaults,
    ...captionsCustom
  }

  const cols = evaluate(state, columns, {})

  const addIsDisabled =
    readOnly || (maxEntries && Object.keys(data).length >= maxEntries)

  return (
    <>
      <Info info={info} />
      {deleteObject && (
        <Confirm
          title={captions.deleteTitle}
          caption="Löschen"
          show={showConfirm}
          setShow={setShowConfirm}
          onConfirmed={e => {
            remove(deleteId)
            setShowConfirm(false)
          }}
        >
          {captions.deleteConfirm(deleteObject)}
        </Confirm>
      )}
      <DataTable
        columns={cols}
        data={data}
        editRow={id => editEntry(id)}
        deleteRow={id => {
          setDeleteId(id)
          setShowConfirm(true)
        }}
        renderColumn={renderColumn(schema)}
        readOnly={readOnly}
      />
      <Button
        disabled={addIsDisabled}
        variant="success"
        onClick={e => editEntry(null)}
      >
        {captions.add}
      </Button>
      <ModalForm
        title={mf.data?.id ? captions.edit : captions.create}
        show={showForm}
        setShow={setShowForm}
        save={() => {
          if (mf.validation?.countErrors > 0) {
            setShowValidation(true)
          } else {
            upsert({
              id: generateUuid(),
              ...mf.data
            })
            cleanup()
          }
        }}
        cancel={() => cleanup()}
      >
        <FormElement
          {...rest}
          prefix={listName}
          showValidation={showValidation}
          schema={children}
        />
      </ModalForm>
    </>
  )
}
