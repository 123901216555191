import React from 'react'
import { useSelector } from 'react-redux'

import { getData } from '../../selectors'
import htmlToReact from '../../htmlToReact'
import VariableName from '../VariableName'

export default ({ schema: { name, html } }) => {
  const value = useSelector(getData(name))
  const data = useSelector(({ data }) => data)
  const content = typeof html === 'function' ? html(data) : html

  return (
    <div>
      <VariableName name={name} value={value && JSON.stringify(value, null, 2)}/>
      {htmlToReact(content)}
    </div>
  )
}
